import React from 'react';
import typeHelper from '../../../infrastructure/helpers/common/typeHelper';
import ColumnDefinition from '../../../infrastructure/types/DataGrid/ColumnDefinition';
import AccuracyValidation from '../../../types/report/AccuracyValidation';
import CustomIcon from '../../Common/BootstrapIcons/Custom/CustomIcon';
import iconTooltip from '../../Common/BootstrapIcons/Tooltip/IconTooltip';
import { ReactComponent as AssignmentIcon } from '../../Common/BootstrapIcons/assignment-late.svg';
import { ReactComponent as RemoveIcon } from '../../Common/BootstrapIcons/close.svg';
import { ReactComponent as CommentIcon } from '../../Common/BootstrapIcons/comment.svg';
import { ReactComponent as CopyIcon } from '../../Common/BootstrapIcons/copy.svg';
import { ReactComponent as HistoryIcon } from '../../Common/BootstrapIcons/history.svg';
import { ReactComponent as LinkIcon } from '../../Common/BootstrapIcons/link.svg';
import { ReactComponent as InfoIcon } from '../../Common/BootstrapIcons/info.svg';
import EditableText from '../../Common/EditableText/EditableText';
import PromiseButton from '../../Common/PromiseButton';

const getColumns = (
    onValidationChange: (changes: AccuracyValidation, number: number, event?: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void,
    onRemoveClick: (number: number, event?: React.MouseEvent<HTMLButtonElement>) => void,
    onSourceClick: (number: number, event?: React.MouseEvent<HTMLButtonElement>) => void,
    onInfoClick: (number: number, event?: React.MouseEvent<HTMLButtonElement>) => void,
    onCommentClick: (id: number, event?: React.MouseEvent<HTMLButtonElement>) => Promise<any>,
    getCommentCount: (id: number) => number,
    getActionItemCount: (id: number, event?: React.MouseEvent<HTMLButtonElement>) => number,
    onActionItemClick: (id: number, event?: React.MouseEvent<HTMLButtonElement>) => Promise<any>,
    onCopyClick: (number: number, event?: React.MouseEvent<HTMLButtonElement>) => void,
    onViewChangelog: (id: number, event?: React.MouseEvent<HTMLButtonElement>) => void,
    isReadOnly: boolean,
    ): ColumnDefinition<AccuracyValidation>[] => {

    let columns: ColumnDefinition<AccuracyValidation>[] = [
        {
            header: 'Validation ID',
            getContent: x =>
                <EditableText
                    value={x.identifier}
                    onChange={(value, event) => onValidationChange({ identifier: value } as AccuracyValidation, x.number, event)}
                    multiline={false}
                    disabled={x.isReadOnly || isReadOnly} />
        },
        {
            header: 'Name',
            getContent: x =>
                <EditableText
                    value={x.name}
                    onChange={(value, event) => onValidationChange({ name: value } as AccuracyValidation, x.number, event)}
                    multiline={false}
                    disabled={x.isReadOnly || isReadOnly} />
        },
        {
            header: 'Report Comment',
            getContent: x =>
                <EditableText
                    value={x.reportComment}
                    onChange={(value, event) => onValidationChange({ reportComment: value } as AccuracyValidation, x.number, event)}
                    multiline={true}
                    disabled={x.isReadOnly || isReadOnly} />
        },
        {
            header: 'Regulatory Text',
            getContent: x =>
                <EditableText
                    value={x.regulatoryText}
                    onChange={(value, event) => onValidationChange({ regulatoryText: value } as AccuracyValidation, x.number, event)}
                    multiline={true}
                    disabled={x.isReadOnly || isReadOnly} />
        },
        {
            header: '',
            getContent: x =>
                <div className="float-right">
                    <button className="custom-icon-button" onClick={(event) => onInfoClick(x.number, event)} disabled={x.isReadOnly || isReadOnly}>
                        <CustomIcon icon={InfoIcon} title={iconTooltip.info} />
                    </button>
                    <button className="custom-icon-button" onClick={(event) => onSourceClick(x.number, event)} disabled={x.isReadOnly || isReadOnly}>
                        <CustomIcon icon={LinkIcon} title={iconTooltip.source} />
                    </button>
                    {
                        typeHelper.isNumber(x.id) &&
                        <>
                            <PromiseButton disabled={ x.isReadOnly} className="custom-icon-button" enableOnErrorOnly={false} task={(event) => onCommentClick(x.id as number, event)}>
                                <CustomIcon icon={CommentIcon} count={getCommentCount(x.id as number)} title={iconTooltip.comments} />
                            </PromiseButton>
                            <PromiseButton disabled={x.isReadOnly} className="custom-icon-button" enableOnErrorOnly={false} task={(event) => onActionItemClick(x.id as number, event)}>
                                <CustomIcon icon={AssignmentIcon} count={getActionItemCount(x.id as number)} title={iconTooltip.actionLog} />
                            </PromiseButton>
                        </>
                    }
                    <button className="custom-icon-button" onClick={(event) => onRemoveClick(x.number, event)} disabled={x.isReadOnly || isReadOnly}>
                        <CustomIcon icon={RemoveIcon} title={iconTooltip.remove} />
                    </button>
                    <button className="custom-icon-button" onClick={(event) => onCopyClick(x.number || 0, event)} disabled={x.isReadOnly || isReadOnly}>
                        <CustomIcon icon={CopyIcon} title={iconTooltip.copy} />
                    </button>
                    {
                        typeHelper.isNumber(x.id) &&
                        <>
                            <button className="custom-icon-button" onClick={(event) => onViewChangelog(x.id || 0, event)}>
                                <CustomIcon icon={HistoryIcon} title={iconTooltip.changeLog} />
                            </button>
                        </>
                    }
                </div>
        }
    ];

    return columns;
};

export { getColumns };
