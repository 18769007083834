import { getFilenameDetails } from '../../../infrastructure/helpers/common/pathUtils';
import megaByteToByte from '../../../infrastructure/helpers/common/conversion/megaByteToByte';
import typeHelper from '../../../infrastructure/helpers/common/typeHelper';

const fileHasExtension = (filename: string, extension: string): boolean => {

    let details = getFilenameDetails(filename);

    if (!typeHelper.isString(details.extension)) {
        return false;
    }

    return details.extension === extension;
};

const validate = (fileList: FileList, maxFileCount: number, allowedExtensions: string[], maxFileSizeInMb: number): string | null => {

    if (!(fileList instanceof FileList) || fileList.length === 0) {
        return 'A file is required';
    }

    if (fileList.length > maxFileCount) {
        return `Allowed max file count is ${maxFileCount}`;
    }

    let files = Array.from(fileList);

    let invalidExtensionFiles = files.filter(f => !allowedExtensions.some(e => fileHasExtension(f.name, e))).map(f => f.name);
    if (invalidExtensionFiles.length > 0) {
        return `The following files have invalid extension: ${invalidExtensionFiles.join(', ')}. Valid extensions: ${allowedExtensions.join(', ')}`;
    }

    if (files.some(f => f.size > megaByteToByte(maxFileSizeInMb))) {

        return `File size limit is ${maxFileSizeInMb}MB`;
    }

    return null;
};

const validateFileInstance = (file: File): string | null => {

    if (!(file instanceof File)) {
        return 'A file is required';
    }

    return null;
};

export { validate, validateFileInstance };