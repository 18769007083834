import React from 'react';
import { ReactComponent as MinusIcon } from '../../../../components/Common/BootstrapIcons/dash.svg';
import { ReactComponent as PlusIcon } from '../../../../components/Common/BootstrapIcons/plus.svg';
import CustomIcon from '../../../Common/BootstrapIcons/Custom/CustomIcon';
import iconTooltip from '../../../Common/BootstrapIcons/Tooltip/IconTooltip';
import { ReactComponent as DownloadIcon } from '../../../Common/BootstrapIcons/download.svg';
import { ReactComponent as UploadIcon } from '../../../Common/BootstrapIcons/upload.svg';
import httpClient from '../../../../infrastructure/helpers/common/httpClient';
import urlHelper from '../../../../infrastructure/helpers/common/urlHelper';
import { validate, validateFileInstance } from '../../../Common/FilePicker/FilePicker.Validation';
import UploadFileModal from '../../../Common/Modals/UploadFileModal/UploadFIleModal';
import fileExtension from '../../../../infrastructure/constants/fileExtension';
import reportConfigNavigation from '../../../../infrastructure/helpers/report/navigation/reportConfigNavigation';
import { message } from '../../../Common/NavigationPrompt/NavigationPrompt.Helpers';
import reportComponentBuildersHelper from '../../Common/ReportComponentsHelper';
interface Props {
    heading: string;
    htmlId: string;
    onClick: (htmlId: string) => void;
    isExpanded: (htmlId: string) => boolean;
    regime: string;
    validationKind: string;
}

interface State {
    showFileUploadPopup: boolean;
}

class ValidationSection extends React.Component<Props, State>{

    constructor(props: Props) {
        super(props);

        this.state = {
            showFileUploadPopup: false
        }

        this.onClick = this.onClick.bind(this);
        this.onUploadValidationsClick = this.onUploadValidationsClick.bind(this);
        this.onDownloadValidationsClick = this.onDownloadValidationsClick.bind(this);
        this.onUploadFilePicked = this.onUploadFilePicked.bind(this);
        this.onValidationsUpdated = this.onValidationsUpdated.bind(this);
    }

    onUploadValidationsClick() {
        this.setState({ showFileUploadPopup: true });
    }

    getUploadData(file: File): FormData {
        let data = new FormData();

        data.append('file', file as File);

        return data;
    }

    async onDownloadValidationsClick() {

        let route = urlHelper.buildRoute(['report', 'validations', this.props.validationKind, this.props.regime]);

        await httpClient.download(route);

        this.setState({ showFileUploadPopup: false });
    }

    onClick(): void {
        this.props.onClick(this.props.htmlId);
    }

    getClassName(): string {
        let classes = ['collapse'];

        if (this.props.isExpanded(this.props.htmlId)) {
            classes.push('show');
        }

        return classes.join(' ');
    }

    resolveIcon(): JSX.Element {

        return this.props.isExpanded(this.props.htmlId) ? <MinusIcon /> : <PlusIcon />;
    }

    resolveTooltip(): string {

        return this.props.isExpanded(this.props.htmlId) ? 'collapse' : 'expand';
    }

    onValidationsUpdated() {
        reportConfigNavigation.refreshPage();

        let message = 'Bulk update completed. Please change history.';

        reportComponentBuildersHelper.showMesage(message, true);
    }

    async onUploadFilePicked(files: FileList): Promise<void> {

        let data = this.getUploadData(files[0]);

        var path = urlHelper.buildRoute(['report', 'validations', this.props.validationKind, this.props.regime]);

        await httpClient.postFile(path, data, {});

        this.setState({ showFileUploadPopup: false }, this.onValidationsUpdated);
    }

    render(): JSX.Element {

        return (
            <>
                <div className="section-heading" style={{ paddingLeft: '25px' }}>

                    <div className='display-flex  flex-align-center'>
                        <h5 id={this.props.htmlId}>

                            {this.props.heading}

                            <button id={`btn-expand-${this.props.htmlId}`} className="transparent-button" onClick={this.onClick} title={this.resolveTooltip()}>
                                {this.resolveIcon()}
                            </button>
                        </h5>
                        <div>
                            <button type="button" className="bg-none border-none" onClick={this.onDownloadValidationsClick}>
                                <CustomIcon icon={DownloadIcon} title={iconTooltip.download} />
                            </button>
                            <button type="button" className="bg-none border-none" onClick={this.onUploadValidationsClick}>
                                <CustomIcon icon={UploadIcon} title={iconTooltip.upload} />
                            </button>
                        </div>
                    </div>
                </div >

                <div className={this.getClassName()} style={{ paddingLeft: '25px' }}>
                    {this.props.children}
                </div>
                {this.state.showFileUploadPopup &&
                    <UploadFileModal
                        allowedExtensions={['csv']}
                        onClose={() => { this.setState({ showFileUploadPopup: false }) }}
                        uniqueName='validationsUpload'
                        onFilePicked={this.onUploadFilePicked}
                    />
                }
            </>
        );
    }
}

export default ValidationSection;
