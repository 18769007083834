import EditSourceModalState from "./EditSourceModalState";

const getDefaultState = (): EditSourceModalState => {
    return {
        title: '',
        isOpen: false,
        error: null,
        number: 0,
        source: '',
        sourceUrl: '',
    };
};

export { getDefaultState };
