import AccuracyValidation from '../../../../types/report/AccuracyValidation';
import Assignment from '../../../../types/functions/Assignment/Assignment';
import booleanLiteral from '../../../constants/functions/booleanLiteral';
import conditionHelper from '../condition/conditionHelper';
import dataType from '../../../constants/dataType';
import NumberDeclarationHelper from '../../../types/Functions/NumberDeclarationHelper';
import Operand from '../../../../types/functions/Operand/Operand';
import operandType from '../../../constants/functions/operandType';
import ReportConfig from '../../../../types/report/ReportConfig';
import ReportLogicLocation from '../../../../types/functions/Location/ReportLogicLocation';

class AccuracyValidationHelper extends NumberDeclarationHelper<AccuracyValidation> {

    getDeclarations(config: ReportConfig, _location: ReportLogicLocation): AccuracyValidation[] {

        return config.validations.sort(function (a, b) {
            return a.number - b.number;
        });
    }

    getEmpty(_rawDataSourceId: number | null): AccuracyValidation {

        let assignment = this.getDefaultAssignment();

        return {
            id: null,
            number: 0,
            identifier: '',
            name: '',
            regulatoryText: '',
            reportComment: '',
            source: '',
            sourceUrl: '',
            internalComments: '',
            internalCommentsUrl: '',
            assignment: assignment,
            isBespoke: false,
            bespokeAssignment: '',
            errors: [],
            dataSourceId: null,
            kind: ''
        };
    }

    getDefaultAssignment(): Assignment {

        let $true = { operandType: operandType.constant, dataType: dataType.boolean, value: booleanLiteral.true } as Operand;
        let $false = { operandType: operandType.constant, dataType: dataType.boolean, value: booleanLiteral.false } as Operand;

        return {
            number: 1,
            condition: conditionHelper.getEmpty(),
            value: $true,
            doNotPopulate: false,
            elseIf: null,
            else: {
                number: 2,
                condition: null,
                value: $false,
                doNotPopulate: false,
                elseIf: null,
                else: null
            }
        };
    }
}

const accuracyValidationHelper = new AccuracyValidationHelper();

export default accuracyValidationHelper;
