import AccuracyValidation from '../../../../types/report/AccuracyValidation';
import accuracyValidation from '../../../constants/functions/accuracyValidation';
import bespokeValidator from './bespokeValidator';
import clear from '../../common/array/clear';
import CustomDataSourceFields from '../../../../types/report/CustomDataSourceFields';
import declarationValidator from './declarationValidator';
import IngestionConfig from '../../../../types/ingestion/IngestionConfig';
import Lookup from '../../../../types/functions/Lookup';
import ReportFieldDefinition from '../../../../types/report/ReportFieldDefinition';
import ReportVariable from '../../../../types/report/ReportVariable';
import stringHelper from '../../common/stringHelper';
import uniquenessValidator from './uniquenessValidator';
import Dictionary from '../../../../types/report/Dictionary';
import UserDefinedFunction from '../../../../types/report/UserDefinedFunction';

const maxIdentifierCharacters = 50;
const maxNameCharacters = 500;
const maxReportCommentCharacters = 3000;
const maxRegulatoryTextCharacters = 3000;

const validateValidation = (validation: AccuracyValidation, functions: UserDefinedFunction[], lookups: Lookup[], dictionaries: Dictionary[], variables: ReportVariable[], dataSources: IngestionConfig[], reportFields: ReportFieldDefinition[], customDataSourceFields: CustomDataSourceFields): void => {

    clear(validation.errors);

    let metadataError = validateMetadata(validation);
    if (metadataError) {
        validation.errors.push(metadataError);
    }

    if (validation.isBespoke) {

        let error = bespokeValidator.validateAssignment(validation.bespokeAssignment);
        if (error) {
            validation.errors.push(error);
        }

        return;
    }

    declarationValidator.validate(validation, functions, lookups, dictionaries, variables, dataSources, reportFields, customDataSourceFields, accuracyValidation.type, null, false);
};

const validateMetadata = (validation: AccuracyValidation): string | null => {

    if (stringHelper.isEmpty(validation.identifier)) {

        return 'Validation ID is required';
    }

    if (validation.identifier.length > maxIdentifierCharacters) {

        return `Validation ID max character count is ${maxIdentifierCharacters}`;
    }

    if (validation.name?.length > maxNameCharacters) {

        return `Name max character count is ${maxNameCharacters}`;
    }

    if (validation.regulatoryText?.length > maxRegulatoryTextCharacters) {

        return `Regulatory Text max character count is ${maxRegulatoryTextCharacters}`;
    }

    if (validation.reportComment?.length > maxReportCommentCharacters) {

        return `Report Comment max character count is ${maxReportCommentCharacters}`;
    }

    return null;
};

const validate = (validations: AccuracyValidation[], functions: UserDefinedFunction[], lookups: Lookup[], dictionaries: Dictionary[], variables: ReportVariable[], dataSources: IngestionConfig[], reportFields: ReportFieldDefinition[], customDataSourceFields: CustomDataSourceFields): string | null => {

    validations.forEach(x => validateValidation(x, functions, lookups, dictionaries, variables, dataSources, reportFields, customDataSourceFields));

    uniquenessValidator.validate(validations, v => v.identifier, 'Validation', 'ID');

    let valid = validations.every(x => x.errors.length === 0);

    return valid ? null : 'Invalid validation(s)';
};

const accuracyValidationValidator = {
    validate,
    validateValidation
};

export default accuracyValidationValidator;
