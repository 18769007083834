import React from "react";
import FilePicker from "../../FilePicker/FilePicker";
import Modal from "../../Modal/Modal";
import actions from "../../../../store/actions";
import store from "../../../../store/store";
import { validateFileInstance } from "../../FilePicker/FilePicker.Validation";

interface ModalProps {
    uniqueName: string;
    allowedExtensions: string[];
    maxFileCount?: number;
    maxFileSizeInMb?: number;
    autoReset?: boolean;
    height?: number;
    onFilePicked: (files: FileList) => void;
    title?: string | null;
    onClose: () => void;
}

interface ModalState {
    file: File | null,
    error: string
}

class UploadFileModal extends React.Component<ModalProps, ModalState> {
    constructor(props: ModalProps) {
        super(props);

        this.state = {
            file: null,
            error: ''
        }

        this.onFilePicked = this.onFilePicked.bind(this);
    }

    onFilePicked(files: FileList, error: string): void {

        store.dispatch({ type: actions.navigation.setUnsafe, payload: null as any });

        let fileError: string |null=null;

        for (var i = 0; i < files.length; i++) {

            fileError = validateFileInstance(files[i]);
            if (fileError) {
                break;
            }
        }

        if (fileError) {
            this.setState({ error: fileError });
        }
        else {
            this.props.onFilePicked(files);
        }
    }

    render(): JSX.Element {
        return (
            <Modal state={{ isOpen: true, title: this.props.title || 'Upload file', error: this.state.error }} close={this.props.onClose}>
                < FilePicker
                    uniqueName="ingestion"
                    allowedExtensions={this.props.allowedExtensions}
                    maxFileCount={this.props.maxFileCount || 1}
                    maxFileSizeInMb={this.props.maxFileSizeInMb || 10}
                    autoReset={false}
                    height={this.props.height || 200}
                    onPicked={this.onFilePicked} />

            </Modal>
        );
    }
}

export default UploadFileModal;
