import { createDirectDataSourceOption, createFieldOption, createLookupOption, createDictionaryOption, createReportFieldOption, createVariableOption, lookupOption, reportOption, variableOption, emptyOption } from './FieldValueBuilder.Helpers';
import { SelectSearchOption, SelectedOptionValue } from 'react-select-search';
import BaseOperandProps from '../Props/BaseOperandProps';
import dataSourceHelper from '../../../../infrastructure/helpers/functions/common/dataSourceHelper';
import FieldOperandProps from '../Props/FieldOperandProps';
import IngestionConfig from '../../../../types/ingestion/IngestionConfig';
import locationType from '../../../../types/functions/Location/LocationType';
import Lookup from '../../../../types/functions/Lookup';
import mixedOperandHelper from '../../../../infrastructure/helpers/functions/operand/mixedOperandHelper';
import Operand from '../../../../types/functions/Operand/Operand';
import operandHelper from '../../../../infrastructure/helpers/functions/operand/operandHelper';
import operandType from '../../../../infrastructure/constants/functions/operandType';
import React from 'react';
import ReportFieldDefinition from '../../../../types/report/ReportFieldDefinition';
import reportLogicLocationHelper from '../../../../infrastructure/helpers/functions/report/reportLogicLocationHelper';
import ReportVariable from '../../../../types/report/ReportVariable';
import SelectList from '../../../Common/SelectList/SelectList';
import SelectOption from '../../../../infrastructure/types/SelectOption';
import SelectSearch from 'react-select-search';
import stringHelper from '../../../../infrastructure/helpers/common/stringHelper';
import typeHelper from '../../../../infrastructure/helpers/common/typeHelper';
import Dictionary from '../../../../types/report/Dictionary';
import repeat from '../../../../infrastructure/helpers/common/array/repeat';
import dictionaryHelper from '../../../../infrastructure/helpers/functions/dictionaries/dictionaryHelper';
import { reportType } from '../../../../infrastructure/constants/reportType';
import dataSourceKind from '../../../../infrastructure/constants/dataSourceKind';

type FieldValueBuilderProps = BaseOperandProps &
    FieldOperandProps &
{
    reportType: string,
};


class FieldValueBuilder extends React.Component<FieldValueBuilderProps> {

    constructor(props: FieldValueBuilderProps) {
        super(props);

        this.onDataSourceChange = this.onDataSourceChange.bind(this);
        this.onFieldChange = this.onFieldChange.bind(this);
        this.getDataSourceOptions = this.getDataSourceOptions.bind(this);
        this.getFieldOptions = this.getFieldOptions.bind(this);
        this.getFieldValue = this.getFieldValue.bind(this);
        this.qualifiesOperand1DataSource = this.qualifiesOperand1DataSource.bind(this);
        this.qualifiesOperand2DataSource = this.qualifiesOperand2DataSource.bind(this);
        this.qualifiesForLookupOption = this.qualifiesForLookupOption.bind(this);
        this.qualifiesForReportOption = this.qualifiesForReportOption.bind(this);
        this.qualifiesForVariableOption = this.qualifiesForVariableOption.bind(this);
        this.qualifiesForReportField = this.qualifiesForReportField.bind(this);
        this.qualifiesForVariable = this.qualifiesForVariable.bind(this);
        this.qualifiesForDictionaryOption = this.qualifiesForDictionaryOption.bind(this);
    }

    onDataSourceChange(value: string): void {

        let operand: Operand;

        if (stringHelper.isEmpty(value)) {

            operand = operandHelper.getEmpty();
            operand.operandType = operandType.field;
        }
        else {
            operand = mixedOperandHelper.getOperand(value, this.props.lookups, this.props.variables, this.props.collections.dataSources, this.props.collections.reportFields, this.props.dictionaries);
        }

        this.props.onOperandChange(operand);
    }

    onFieldChange(selectedValue: SelectedOptionValue | SelectedOptionValue[]): void {
        let value = selectedValue.toString();

        if (stringHelper.isEmpty(value)) {
            return;
        }

        let operand = {} as Operand;

        if (this.props.operand.operandType === operandType.reportField) {

            operand.reportFieldId = parseInt(value);
        }
        else if (this.props.operand.operandType === operandType.variable) {

            operand.variableNumber = parseInt(value);
        }
        else if (this.props.operand.operandType === operandType.lookupField) {
            const val = value.split('-');
            operand.lookupNumber = parseInt(val[0]);
            operand.fieldId = parseInt(val[1]);
        }
        else {
            operand.fieldId = parseInt(value);
        }

        if (this.props.operand.operandType === operandType.dictionary) {

            let dictionary = dictionaryHelper.getByNumber(this.props.dictionaries, this.props.operand.dictionaryNumber);

            operand.arguments = repeat(dictionary.fields.length, operandHelper.getEmpty);
        }

        this.props.onOperandChange(operand);
    }

    getDataSourceOptions(): SelectOption[] {
        let result: SelectOption[] = [];
        let isLookup = this.props.location.statement === locationType.statement.lookup;

        if (isLookup && this.props.location.operandNumber === 2) {

            return this.props.collections.dataSources.filter(this.qualifiesOperand2DataSource).map(createDirectDataSourceOption);
        }

        if (this.props.location.statement !== locationType.statement.endpointFilter &&
            this.props.location.statement !== locationType.statement.overReportFilter) {

            let dataSourceOptions = this.props.collections.dataSources.filter(this.qualifiesOperand1DataSource).map(createDirectDataSourceOption);

            result = result.concat(dataSourceOptions);

            if (this.props.lookups.filter(this.qualifiesForLookupOption).length) {
                result.push(lookupOption);
            }
        }

        if (this.qualifiesForReportOption()) {

            let reportOptionText = '[Report]';

            result.push(reportOption(reportOptionText));
        }

        if (this.qualifiesForVariableOption()) {

            result.push(variableOption);
        }

        this.props.dictionaries.filter(this.qualifiesForDictionaryOption).forEach(x => result.push(createDictionaryOption(x, this.props.collections.dataSources)));

        return result;
    }

    getFieldOptions(): SelectSearchOption[] {

        if (operandHelper.isValidField(this.props.operand)) {

            let dataSource = dataSourceHelper.getByFieldId(this.props.collections.dataSources, parseInt(this.props.operand?.fieldId?.toString() || '0'));

            return dataSource.fieldDefinitions.map(createFieldOption);
        }

        if (this.props.operand.operandType === operandType.lookupField) {
            let options: SelectSearchOption[] = [];

            this.props.lookups.filter(this.qualifiesForLookupOption).forEach(((lookup) => {
                options = options.concat(createLookupOption(lookup, this.props.collections.dataSources));
            }));

            return options;
        }

        if (operandHelper.isValidReportField(this.props.operand)) {

            let number = typeHelper.isNumber(this.props.location.fieldId) ?
                (this.props.collections.reportFields.find(x => x.id === this.props.location.fieldId) as ReportFieldDefinition).number :
                null;

            return this.props.collections.reportFields.filter(x => this.qualifiesForReportField(x, number)).map(createReportFieldOption);
        }

        if (operandHelper.isValidVariable(this.props.operand)) {

            return this.props.variables.filter(this.qualifiesForVariable).map(createVariableOption);
        }

        if (operandHelper.isValidDictionaryField(this.props.operand)) {

            let dataSource = dataSourceHelper.getByFieldId(this.props.collections.dataSources, this.props.operand.fieldId as number);

            return dataSource.fieldDefinitions.map(createFieldOption);
        }

        return [emptyOption]
    }

    getFieldValue(): string {

        if (operandHelper.isValidField(this.props.operand) ||
            operandHelper.isValidDictionaryField(this.props.operand)) {

            return (this.props.operand.fieldId as number).toString();
        }

        if (operandHelper.isValidReportField(this.props.operand)) {

            return (this.props.operand.reportFieldId as number).toString();
        }

        if (operandHelper.isValidVariable(this.props.operand)) {

            return (this.props.operand.variableNumber as number).toString();
        }

        if (this.props.operand.operandType === operandType.lookupField) {

            const value = this.props.operand.lookupNumber + '-' + this.props.operand.fieldId;
            return value;
        }

        return '';
    }

    qualifiesOperand1DataSource(config: IngestionConfig): boolean {

        if (config.id === this.props.dataSource1Id) {

            return true;
        }

        if (config.id === this.props.dataSource2Id &&
            this.props.location.statement === locationType.statement.lookup &&
            !reportLogicLocationHelper.isRoot(this.props.location)) {

            return true;
        }

        return false;
    }

    qualifiesOperand2DataSource(config: IngestionConfig): boolean {

        if (config.id === this.props.dataSource2Id) {

            return true;
        }

        if (config.id === this.props.dataSource1Id &&
            this.props.location.statement === locationType.statement.lookup &&
            !reportLogicLocationHelper.isRoot(this.props.location)) {

            return true;
        }

        if (config.id !== this.props.dataSource1Id &&
            !typeHelper.isNumber(this.props.dataSource2Id)) {

            return true;
        }

        return false;
    }

    qualifiesForDictionaryOption(dictionary: Dictionary): boolean {

        if (this.props.location.statement === locationType.statement.dictionaryField) {

            return this.props.location.statementNumber > dictionary.number;
        }

        return (
            this.props.location.statement !== locationType.statement.dictionary ||
            this.props.location.statementNumber > dictionary.number
        );
    }

    qualifiesForLookupOption(lookup: Lookup): boolean {

        if (lookup.isMultiResult) {
            return false;
        }

        if (!typeHelper.isNumber(lookup.dataSource2Id)) {

            return false;
        }

        if (this.props.reportType === reportType.accuracy &&
            this.props.location.statement === locationType.statement.filter) {
            return true;
        }

        if ((lookup.dataSource1Id && lookup.dataSource1Id !== this.props.dataSource1Id) ||
            (this.props.dataSource1Id && lookup.dataSource1Id !== this.props.dataSource1Id)) {
            return false;
        }

        if (this.props.location.statement === locationType.statement.dictionaryField) {

            return false;
        }

        return (
            this.props.location.statement !== locationType.statement.lookup ||
            this.props.location.statementNumber > lookup.number
        );
    }

    qualifiesForReportOption(): boolean {

        if (this.props.location.statement === locationType.statement.endpointFilter ||
            this.props.location.statement === locationType.statement.overReportFilter ||
            this.props.location.statement === locationType.statement.filter ||
            this.props.location.statement === locationType.statement.matchingKey ||
            this.props.location.statement === locationType.statement.matchingKeyEndPoint ||
            this.props.location.statement === locationType.statement.variable ||
            this.props.location.statement === locationType.statement.validation) {

            return true;
        }

        if (this.props.location.statement === locationType.statement.isinSelector ||
            this.props.location.statement === locationType.statement.leiSelector ||
            this.props.location.statement === locationType.statement.exchangeRatesSelector ||
            this.props.location.statement === locationType.statement.annaDsbUpiEnrichmentSelector ||
            this.props.location.statement === locationType.statement.annaDsbUpiSelector ||
            this.props.location.statement === locationType.statement.lseSelector ||
            this.props.location.statement === locationType.statement.firdsFcaInstrumentSelector ||
            this.props.location.statement === locationType.statement.firdsEsmaInstrumentSelector ||
            this.props.location.statement === locationType.statement.fcaRegulatedEntitiesSelector) {

            return this.props.dataSource1Id === null;
        }

        if (this.props.location.statement === locationType.statement.lookup) {

            let lookup = this.props.lookups.find(x => x.number === this.props.location.statementNumber) as Lookup;

            return typeHelper.isObject(lookup) && lookup.isEndpoint;
        }

        if (this.props.location.statement === locationType.statement.reportField) {

            let field = this.props.collections.reportFields.find(x => x.id === this.props.location.fieldId) as ReportFieldDefinition;

            return field.number > 1;
        }

        return false;
    }

    qualifiesForVariableOption(): boolean {

        if (this.props.location.statement === locationType.statement.aggregation ||
            this.props.location.statement === locationType.statement.aggregatedRecordField ||
            this.props.location.statement === locationType.statement.filter ||
            this.props.location.statement === locationType.statement.case ||
            this.props.location.statement === locationType.statement.reportField ||
            this.props.location.statement === locationType.statement.validation ||
            this.props.location.statement === locationType.statement.variable ||
            this.props.location.statement === locationType.statement.underReportFilter) {

            return this.props.variables.some(this.qualifiesForVariable);
        }

        return false;
    }

    qualifiesForReportField(field: ReportFieldDefinition, number: number | null): boolean {
        //Define all sections where to display the "REPORT" dropdown options
        const returnTrueForTheseLocations = [
            locationType.statement.endpointFilter,
            locationType.statement.overReportFilter,
            locationType.statement.matchingKey,
            locationType.statement.matchingKeyEndPoint,
            locationType.statement.validation,
            locationType.statement.lookup,
            locationType.statement.variable,
            locationType.statement.filter,
            locationType.statement.leiSelector,
            locationType.statement.isinSelector,
            locationType.statement.exchangeRatesSelector,
            locationType.statement.annaDsbUpiEnrichmentSelector,
            locationType.statement.annaDsbUpiSelector,
            locationType.statement.lseSelector,
            locationType.statement.firdsFcaInstrumentSelector,
            locationType.statement.firdsEsmaInstrumentSelector,
            locationType.statement.fcaRegulatedEntitiesSelector
        ];
        if (!typeHelper.isNumber(number) &&
            returnTrueForTheseLocations.includes(this.props.location.statement)) {
            return true;
        }

        return field.number < (number as number);
    }

    qualifiesForVariable(variable: ReportVariable): boolean {

        if (this.props.reportType === reportType.accuracy &&
            this.props.location.statement === locationType.statement.filter) {

            return true;
        }

        if ((variable.dataSourceId && variable.dataSourceId !== this.props.dataSource1Id) ||
            (this.props.dataSource1Id && variable.dataSourceId !== this.props.dataSource1Id)) {
            return false;
        }

        return this.props.location.statement !== locationType.statement.variable || this.props.location.statementNumber > variable.number;
    }

    render(): JSX.Element {
        return (
            <div className="row">
                <div className="col">
                    <SelectList
                        options={this.getDataSourceOptions()}
                        value={mixedOperandHelper.buildIdentifier(this.props.operand.fieldId, this.props.operand.dictionaryNumber, this.props.operand.lookupNumber, this.props.operand.variableNumber, this.props.operand.reportFieldId, this.props.collections.dataSources)}
                        onChange={this.onDataSourceChange}
                        includeEmptyOption={true}
                        className="form-control" />
                </div>
                <div className="col">
                    <SelectSearch
                        options={this.getFieldOptions()}
                        value={this.getFieldValue()}
                        onChange={this.onFieldChange}
                        search={true}
                        disabled={!typeHelper.isNumber(this.props.operand.fieldId) && !typeHelper.isNumber(this.props.operand.reportFieldId) && !typeHelper.isNumber(this.props.operand.variableNumber)} />
                </div>
            </div>
        );
    }
}

export default FieldValueBuilder;
