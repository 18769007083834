import stringHelper from "../../../../infrastructure/helpers/common/stringHelper";
import EditSourceModalState from "./EditSourceModalState";

const maxSourceCharacters = 250;
const maxSourceUrlCharacters = 250;

const validate = (state: EditSourceModalState): string | null => {

    if (!stringHelper.isEmpty(state.source) &&
        state.source.length > maxSourceCharacters) {

        return `Source max character count is ${maxSourceCharacters}`;
    }

    if (!stringHelper.isEmpty(state.sourceUrl) &&
        state.sourceUrl.length > maxSourceUrlCharacters) {

        return `Source URL max character count is ${maxSourceUrlCharacters}`;
    }

    return null;
};

export { validate };
