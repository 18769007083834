import stringHelper from "../../../../infrastructure/helpers/common/stringHelper";
import EditInfoModalState from "./EditInfoModalState";

const maxCommentCharacters = 3000;
const maxUrlCharacters = 250;

const validate = (state: EditInfoModalState): string | null => {

    if (!stringHelper.isEmpty(state.comment) &&
        state.comment.length > maxCommentCharacters) {

        return `Internal Comments max character count is ${maxCommentCharacters}`;
    }

    if (!stringHelper.isEmpty(state.url) &&
        state.url.length > maxUrlCharacters) {

        return `Internal Comments URL max character count is ${maxUrlCharacters}`;
    }

    return null;
};

export { validate };
