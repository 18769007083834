import separatedValueHelper from '../../../infrastructure/helpers/ingestion/separatedValueHelper';
import stringHelper from '../../../infrastructure/helpers/common/stringHelper';

const regex = /^[A-Za-z0-9\s_*-.]+$/;

const asterisk = '*';

const validate = (filename: string): string | null => {

    if (stringHelper.isEmpty(filename)) {

        return 'File name is required';
    }

    if (!regex.test(filename)) {

        return `File name '${filename}' contains invalid characters`;
    }

    if (filename.trim() === asterisk) {

        return `'${filename}' is invalid file name`;
    }

    return null;
};

const validateFilenames = (filenames: string): string | null => {

    let names = separatedValueHelper.split(filenames);

    for (let i = 0; i < names.length; i++) {

        let error = validate(names[i]);
        if (error) {

            return error;
        }
    }

    return null;
};

export default validateFilenames;
