import React from 'react';
import { Unsubscribe } from 'redux';
import actions from '../../../../store/actions';
import store from '../../../../store/store';
import EditableText from '../../../Common/EditableText/EditableText';
import Modal from '../../../Common/Modal/Modal';
import EditInfoModalState from './EditInfoModalState';
import { getDefaultState } from './EditInfoModal.Helpers';
import { validate } from './EditInfoModal.Validation';
import RichTextEditor from '../../../Common/RichTextEditor/RichTextEditor';

interface Props {}

class EditInfoModal extends React.Component<Props, EditInfoModalState> {
    private unsubscribe: Unsubscribe | undefined;

    constructor(props: Props) {
        super(props);

        this.state = getDefaultState();

        this.onOpen = this.onOpen.bind(this);
        this.onCommentChange = this.onCommentChange.bind(this);
        this.onUrlChange = this.onUrlChange.bind(this);
        this.onSaveClick = this.onSaveClick.bind(this);
        this.close = this.close.bind(this);
    }

    componentDidMount(): void {
        this.unsubscribe = store.subscribe(this.onOpen);
    }

    componentWillUnmount(): void {
        (this.unsubscribe as Unsubscribe)();
    }

    onOpen(): void {
        let state = store.getState();

        if (state.action !== actions.editInfoModal.change) {
            return;
        }

        this.setState(state.editInfoModalChange);
    }

    onCommentChange(value: string) {

        this.setState({ comment: value } as EditInfoModalState);
    }

    onUrlChange(value: any) {

        this.setState({ url: value } as EditInfoModalState);
    }

    onSaveClick(): void {

        let error = validate(this.state);

        if (error) {
            this.setState({ error: error });
            return;
        }

        store.dispatch({ type: actions.editInfoModal.save, payload: this.state });

        var defaultState = getDefaultState();
        this.setState(defaultState);

        this.close();
    }

    close(): void {
        this.setState({ isOpen: false });
    }

    render(): JSX.Element {

        return (
            <>
                <Modal state={this.state} close={this.close} size="large">

                    <h6 className="mb-3">Comment</h6>

                    {
                        <RichTextEditor
                            html={this.state.comment}
                            update={this.onCommentChange} />
                    }

                    <h6 className="mt-4 mb-3">URL</h6>

                    {
                        <EditableText
                            multiline={false}
                            value={this.state.url}
                            onChange={this.onUrlChange}
                            disabled={false} />
                    }

                    <div className="mt-3 mb-3">
                        <button className="btn cb-btn" onClick={this.onSaveClick}>Save</button>
                    </div>

                </Modal>
            </>
        );
    }
}

export default EditInfoModal;