import EditInfoModalState from "./EditInfoModalState";

const getDefaultState = (): EditInfoModalState => {
    return {
        title: '',
        isOpen: false,
        error: null,
        number: 0,
        comment: '',
        url: '',
    };
};

export { getDefaultState };
